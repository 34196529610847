import React, { useEffect, useState } from "react";
import styles from '../styles/CurrentPhaseDisplay.module.css'; // Assuming you have a CSS module for styling
import { calculateWeeksBetweenDates } from "../utils";

export const getPhaseStartDate = (checkIns) => {
    // find the first check-in of the current phase
    let currentPhase = null;
    let phaseStartDate = null;

    // iterate backwards to find the current phase and its first occurrence
    for (let i = checkIns.length - 1; i >= 0; i--) {
        const checkIn = checkIns[i];
        if (checkIn.phase !== "vacation" && checkIn.phase !== "maintenance") {
            if (!currentPhase) {
                currentPhase = checkIn.phase;
            } else if (checkIn.phase !== currentPhase) {
                // found the first check-in of the current phase
                if (checkIns[i + 1] && checkIns[i + 1].week_start) {
                    phaseStartDate = new Date(checkIns[i + 1].week_start);
                }
                break;
            }
        }
    }

    if (!phaseStartDate && currentPhase && checkIns[0] && checkIns[0].week_start) {
        // if we didn't find a phase change, use the first check-in
        phaseStartDate = new Date(checkIns[0].week_start);
    }

    if (!phaseStartDate || isNaN(phaseStartDate)) {
        return null; // if the date is invalid, return null
    }

    // adjust to the most recent Monday
    const day = phaseStartDate.getDay();
    const diff = day === 0 ? -6 : -((day - 1) % 7); // Adjust backwards to the previous Monday

    phaseStartDate.setDate(phaseStartDate.getDate() + diff);

    return phaseStartDate.toISOString().split("T")[0]; // return date in YYYY-MM-DD format
}





export const adjustToEndOfWeek = (date) => {
    const day = date.getDay();
    const diff = day === 0 ? 0 : 7 - day; // If already Sunday, add 0, otherwise add days until Sunday
    const adjustedDate = new Date(date);
    adjustedDate.setDate(date.getDate() + diff);
    return adjustedDate;
}

export const getPhaseDuration = (checkIns) => {
    const phaseStartDateStr = getPhaseStartDate(checkIns);
    if (!phaseStartDateStr) return 0;

    // Get the most recent check-in date
    const mostRecentCheckIn = checkIns[checkIns.length - 1];
    const phaseStartDate = new Date(phaseStartDateStr);
    const phaseEndDate = adjustToEndOfWeek(new Date(mostRecentCheckIn.week_start));
    
    console.log("Phase Start Date:", phaseStartDate.toISOString());
    console.log("Phase End Date:", phaseEndDate.toISOString());
    
    const totalWeeks = calculateWeeksBetweenDates(phaseStartDate, phaseEndDate);
    console.log("Total Weeks:", totalWeeks);
    
    const roundedWeeks = Math.ceil(totalWeeks);
    console.log("Rounded Weeks:", roundedWeeks);
    
    return roundedWeeks;
}

export const getPhaseAbsoluteChange = (checkIns) => {
    if (!checkIns || checkIns.length < 2) return 0;

    const phaseStartDateStr = getPhaseStartDate(checkIns);
    if (!phaseStartDateStr) return 0;

    // Filter check-ins to only include those in the current phase with valid weights
    const relevantCheckIns = checkIns.filter(checkIn => {
        const startDate = new Date(phaseStartDateStr);
        const checkInDate = new Date(checkIn.week_start);
        return checkInDate >= startDate && 
               checkIn.phase !== "vacation" && 
               checkIn.phase !== "maintenance" &&
               checkIn.avg_weight !== null &&
               checkIn.avg_weight !== 0;
    });

    if (relevantCheckIns.length < 2) return 0;

    // Get first and last valid weight measurements
    const startWeight = relevantCheckIns[0].avg_weight;
    const endWeight = relevantCheckIns[relevantCheckIns.length - 1].avg_weight;

    // Calculate change only if both weights are valid
    if (startWeight && endWeight) {
        return endWeight - startWeight;
    }
    return 0;
}

export const getPhaseAverageWeeklyChange = (checkIns) => {
    if (!checkIns || checkIns.length < 2) return 0;
    
    const absoluteChange = getPhaseAbsoluteChange(checkIns);
    const totalWeeks = getPhaseDuration(checkIns);
    
    return totalWeeks > 0 ? absoluteChange / totalWeeks : 0;
}

// Function to calculate average weekly weight change and absolute weight change
export const calculateMetrics = (checkIns) => {
    if (!checkIns || checkIns.length < 2) {
        return { averageWeeklyChange: 0, absoluteChange: 0 };
    }

    const absoluteChange = getPhaseAbsoluteChange(checkIns);
    const averageWeeklyChange = getPhaseAverageWeeklyChange(checkIns);
    const phaseDurationWeeks = getPhaseDuration(checkIns);

    // console.log("Calculate Metrics - Duration:", phaseDurationWeeks);
    // console.log("Calculate Metrics - Absolute Change:", absoluteChange);
    // console.log("Calculate Metrics - Average Weekly Change:", averageWeeklyChange);

    return { 
        averageWeeklyChange, 
        absoluteChange,
        phaseDurationWeeks
    };
};

export const CurrentPhaseDisplay = ({ checkIns }) => {
    const [metrics, setMetrics] = useState({ 
        averageWeeklyChange: 0, 
        absoluteChange: 0,
        phaseDurationWeeks: 0 
    });

    useEffect(() => {
        const newMetrics = calculateMetrics(checkIns);
        console.log("Setting new metrics:", newMetrics);
        setMetrics(newMetrics);
    }, [checkIns]);

    return (
        <div className={styles.card}>
            <div className={styles.card__content}>
                <p>Duration: {metrics.phaseDurationWeeks} weeks</p>
                <p>Average Weekly Change: {metrics.averageWeeklyChange.toFixed(2)} kg</p>
                <p>Absolute Change: {metrics.absoluteChange.toFixed(2)} kg</p>
            </div>
        </div>
    );
};

export default CurrentPhaseDisplay;
