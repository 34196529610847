import React from 'react';
import styles from '../styles/Footer.module.css';
import DownloadCheckinsButton from './DownloadCheckinsButton';
import { useAuth } from '../Main/AuthContext';
import { useEffect, useState } from 'react';


function Footer() {
  const { isLoggedIn } = useAuth(); 
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const checkViewportSize = () => {
      setIsMobile(window.matchMedia('(max-width: 700px)').matches);
    };

    // Check once and register for updates
    checkViewportSize();
    window.addEventListener('resize', checkViewportSize);

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', checkViewportSize);
  }, []);

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__centerContainer}>
        <p>© {new Date().getFullYear()} Fitness Check-In. All rights reserved.</p>
        <p>Contact: hemposse@hotmail.com</p>
      </div>
      {isLoggedIn && (
      <div className={styles.footer__buttonContainer}>
        {!isMobile && <DownloadCheckinsButton />}
      </div>
      )}

    </footer>
  );
}

export default Footer;
