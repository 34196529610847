import React from 'react';
import styles from '../styles/CheckinButton.module.css';

const CheckinButton = ({ setEditingIndex, setModalOpen }) => {
  return (
    <button 
      className={styles.newCheckInButton} 
      onClick={() => { setEditingIndex(null); setModalOpen(true); }}
    >
      New Check-In
    </button>
  );
};

export default CheckinButton;
