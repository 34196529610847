import React from 'react';
import styles from '../styles/Header.module.css';
import LogoutButton from './LogoutButton';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import { useAuth } from '../Main/AuthContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header({ userName }) {
  
  const { isLoggedIn } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const [isSidebarVisible, setIsSidebarVisible] = useState(!isMobile);


  useEffect(() => {
    const checkViewportSize = () => {
      const matchMobile = window.innerWidth <= 700;
      setIsMobile(matchMobile);
      setIsSidebarVisible(!matchMobile); // Set sidebar visibility based on screen size
    };

    // Register the event listener
    window.addEventListener('resize', checkViewportSize);

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', checkViewportSize);
  }, []);

  // Function to toggle the sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  };

  return (
    <div className={styles.header}>
      {isLoggedIn && (
        <>
          <div className={styles.header__sidebar}>
            <button className={styles.sidebarButton} onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} size="2x" />
            </button>
          </div>
        </>
      )}

      <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
      <div className={styles.header__wrapper}>
        <h1 className={styles.header__title}>Fitness Check-In</h1>
        {isLoggedIn &&
          <h3 className={styles.header__user_name}>{userName}</h3>
        }
      </div>
      {!isMobile && isLoggedIn &&
        <div className={styles.header__logoutButton}>
            <LogoutButton />
        </div>
        }
    </div>
  );
}

export default Header;
