import React from 'react';
import axios from 'axios';
import FileDownload from 'js-file-download';
import styles from '../styles/DownloadCheckinsButton.module.css';

const DownloadCheckinsButton = () => {
    const handleDownload = async () => {
      try {
        const response = await axios.get('/checkin/download', {
          responseType: 'blob', // Important for handling binary data files
        });
        FileDownload(response.data, 'check-ins.csv');
      } catch (error) {
        console.error('Failed to download the file:', error);
      }
    };
  
    return (
      <button className={styles.downloadButton} onClick={handleDownload}>
        Download Check-Ins as CSV
      </button>
    );
  };
  
  export default DownloadCheckinsButton;