import React from 'react';
import axios from 'axios';
import FileDownload from 'js-file-download';
import styles from '../styles/BackupDataButton.module.css';

const BackupDataButton = () => {
    // Handler for downloading check-ins
    const handleDownloadCheckIns = async () => {
      try {
        const response = await axios.get('/download-checkins', { // Adjusted endpoint
          responseType: 'blob',
        });
        FileDownload(response.data, 'all_checkins.csv');
      } catch (error) {
        console.error('Failed to download check-ins:', error);
      }
    };

    // Handler for downloading users
    const handleDownloadUsers = async () => {
      try {
        const response = await axios.get('/download-users', { // Adjusted endpoint
          responseType: 'blob',
        });
        FileDownload(response.data, 'all_users.csv');
      } catch (error) {
        console.error('Failed to download users data:', error);
      }
    };
  
    return (
      <div>
        <button className={styles.downloadButton} onClick={handleDownloadCheckIns}>
          Download Check-Ins as CSV
        </button>
        <button className={styles.downloadButton} onClick={handleDownloadUsers}>
          Download Users as CSV
        </button>
      </div>
    );
};
  
  export default BackupDataButton;