import React from "react";
import { 
  getCurrentPhase, 
  capitalizeFirstLetter 
} from "../utils";
import CurrentPhaseDisplay from "./CurrentPhaseDisplay";
import styles from '../styles/ProgressDisplayTable.module.css';

// Function to get ISO week and year
const getWeekYear = (date) => {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - (tempDate.getDay() + 6) % 7);
    const week1 = new Date(tempDate.getFullYear(), 0, 4);
    const weekYear = tempDate.getFullYear();
    const week = Math.round(((tempDate - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7) + 1;
    return `Week ${week}, ${weekYear}`;
};

const prepareDisplayData = (weeklyAverages) => {
    if (weeklyAverages.length === 0) {
      return [];
    }

    const sortedWeeklyAverages = [...weeklyAverages].sort((a, b) => new Date(a.week_start) - new Date(b.week_start));

    // Identify the current phase, skipping "vacation" and "maintenance" if they are the most recent check-ins
    let currentPhase = null;
    for (let i = sortedWeeklyAverages.length - 1; i >= 0; i--) {
        if (sortedWeeklyAverages[i].phase !== 'vacation' && sortedWeeklyAverages[i].phase !== 'maintenance') {
            currentPhase = sortedWeeklyAverages[i].phase;
            break;
        }
    }

  if (!currentPhase) {
    // If all entries are "vacation" or "maintenance", return an empty array
    return [];
  }

  // Filter check-ins to include only those from the most recent relevant phase
    let filteredWeeklyAverages = [];
    for (let i = sortedWeeklyAverages.length - 1; i >= 0; i--) {
        const phase = sortedWeeklyAverages[i].phase;

    // If a phase switch between "cutting" and "massing" is encountered, stop including further entries
    if ((phase === 'cutting' || phase === 'massing') && phase !== currentPhase) {
      break;
    }

    filteredWeeklyAverages.unshift(sortedWeeklyAverages[i]);
  }

  let displayData = [];
  let cumulativeWeightLoss = [];
  let lastPhase = null;

  filteredWeeklyAverages.forEach((weekData, i) => {
    if (i === 0) {
      lastPhase = weekData.phase;
      displayData.push({
        weekYear: getWeekYear(weekData.week_start),
        weightLoss: 'N/A', // No comparison for the first entry
        phase: weekData.phase,
        averageLoss: null,
        displayStyle: styles.grayRow, // Default style
        displayText: 'N/A' // Updated later based on conditions
      });
      return;
    }

    const prevWeekData = filteredWeeklyAverages[i - 1];
    const weightLoss = weekData.avg_weight - prevWeekData.avg_weight;
    const phase = weekData.phase;
    const weekYear = getWeekYear(weekData.week_start);

    if (phase !== lastPhase || cumulativeWeightLoss.length === 2) {
      if (cumulativeWeightLoss.length === 2) {
        const averageLoss = cumulativeWeightLoss.reduce((acc, curr) => acc + curr, 0) / cumulativeWeightLoss.length;
        const style = getBiweeklyRowStyle(averageLoss, lastPhase);
        if (displayData.length > 1) {
          displayData[displayData.length - 1].averageLoss = averageLoss;
          displayData[displayData.length - 2].averageLoss = averageLoss;
          displayData[displayData.length - 1].displayStyle = style;
          displayData[displayData.length - 2].displayStyle = style;
        }
      }
      cumulativeWeightLoss = [];
      lastPhase = phase;
    }

    displayData.push({
      weekYear,
      weightLoss: weightLoss.toFixed(2),
      phase,
      averageLoss: null,
      displayStyle: styles.grayRow,
      displayText: 'Pending'
    });

    cumulativeWeightLoss.push(weightLoss);

    if (i === filteredWeeklyAverages.length - 1 && cumulativeWeightLoss.length === 2) {
      const averageLoss = cumulativeWeightLoss.reduce((acc, curr) => acc + curr, 0) / cumulativeWeightLoss.length;
      const style = getBiweeklyRowStyle(averageLoss, phase);
      displayData[displayData.length - 1].averageLoss = averageLoss;
      displayData[displayData.length - 1].displayStyle = style;
      displayData[displayData.length - 2].averageLoss = averageLoss;
      displayData[displayData.length - 2].displayStyle = style;
    }
  });

  return displayData.map(item => ({
    ...item,
    displayText: item.averageLoss !== null ? item.averageLoss.toFixed(2) : 'Pending',
  }));
};


const getBiweeklyRowStyle = (averageLoss, phase) => {
  if (phase === 'cutting') {
    if (averageLoss <= -0.5) return styles.greenRow;
    if (averageLoss > -0.5 && averageLoss <= -0.1) return styles.orangeRow;
    return styles.redRow;
  } 
  if (phase === 'massing') {
    if (averageLoss >= 0.01 && averageLoss <= 0.3 ) return styles.greenRow;
    if (averageLoss < -0.15 && averageLoss >= 0) return styles.orangeRow;
    return styles.redRow;
  }
  if (phase === 'maintenance') {
    if (averageLoss >= -1 && averageLoss <= 1) return styles.grayRow;
    return styles.orangeRow;
  }
  if (phase === 'vacation') {
    return styles.grayRow;
  }

  return '';
};

const ProgressDisplayTable = ({ checkIns }) => {
  const displayData = prepareDisplayData(checkIns).reverse();  // Reverse the display data to show the most recent entries at the top
  const currentPhase = capitalizeFirstLetter(getCurrentPhase(checkIns));
  return (
    <div>
      <h2 className={styles.table__header}>{currentPhase} Phase</h2>
      <CurrentPhaseDisplay checkIns={ checkIns }></CurrentPhaseDisplay>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Week</th>
            <th>Weight Loss/Gain This Week</th>
            <th>Phase</th>
            <th>Biweekly Average Loss/Gain</th>
          </tr>
        </thead>
        <tbody>
          {displayData.map((data, index) => (
            <tr key={index} className={data.displayStyle}>
              <td>{data.weekYear}</td>
              <td>{data.weightLoss}</td>
              <td>{data.phase}</td>
              <td>{data.displayText}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProgressDisplayTable;
