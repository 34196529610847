import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/reset.css';
import { AuthProvider } from './Main/AuthContext';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const pageTitle = document.title; // Assuming you set document.title on route changes

// Listen for history changes
history.listen((location, action) => {
  // When the location changes, push a pageview to the dataLayer
  window.dataLayer.push({
    event: 'pageview',
    page: location.pathname + location.search,
    title: pageTitle,
  });
});

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
