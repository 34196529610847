import React from 'react';
import styles from '../styles/Sidebar.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../Main/AuthContext';

const Sidebar = ({ isVisible, toggleSidebar }) => {
  const { isLoggedIn, isAdmin } = useAuth(); 
  
  // Combine isLoggedIn with isVisible to determine if the sidebar should be shown
  const shouldDisplaySidebar = isLoggedIn && isVisible;

  return (
    <div className={shouldDisplaySidebar ? styles.sidebar : styles.sidebarHidden}>
    {shouldDisplaySidebar && (
          <ul> {/* New container */}
            <li><Link to="/main">Home</Link></li>
            <li><Link to="/instructions">Instructions</Link></li>
            <li><Link to="/insights">Insights</Link></li>
            <li><Link to="/leaderboards">Leaderboards</Link></li>
            {isAdmin && (
            <li><Link to="/admin">Admin</Link></li> 
            )}
          </ul>
      )}
    </div>
  );
};

export default Sidebar;
