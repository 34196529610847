import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../styles/InstructionPage.module.css'; // Make sure to create this CSS module

function InstructionPage() {
  const location = useLocation();
  
  useEffect(() => {
    // Remove the leading slash from the pathname if it exists
    const pathWithoutLeadingSlash = location.pathname.startsWith('/')
      ? location.pathname.slice(1)
      : location.pathname;

    // Now set the document title using the modified path
    document.title = `Fitness CheckIn - ${pathWithoutLeadingSlash.replace('/', ' - ')}`;
    // Replace remaining slashes with dashes or any other separator you prefer
  }, [location]);

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>How to use this app</h1>
      <section className={styles.section}>
        <h2 className={styles.subheader}>Getting Started</h2>
        
        <p className={styles.paragraph}>
          1. Weigh yourself at least once a week, the more and consistently the 
          better (optimally daily after wake up)
        </p>

        <p className={styles.paragraph}>
          2. At the end of the week or beginning 
          of the next week, calculate the weekly average weight and input your 
          weight into the app
        </p>
        
        <p className={styles.paragraph}>
          3. If you count calories, add the macro 
          targets you use in your dedicated calorie tracker (the app assumes you 
          follow those) 
        </p>

        <p className={styles.paragraph}>
          4. (Coming soon) If you've been 
          inputting macros and steps, the app will automatically provide 
          recommendations for how to adjust your targets in order to reach your 
          dream physique
        </p>
      </section>
      
      <section className={styles.section}>
        <h2 className={styles.subheader}>Tracking Your Progress</h2>
        <p className={styles.paragraph}>
          Your progress is tracked on the dashboard. You can view your weight trend and step count over time.
        </p>
      </section>
      <section className={styles.section}>
        <h2 className={styles.subheader}>What are my calories?</h2>
        <p className={styles.paragraph}>If you want to get started tracking calories, use this <a href="https://www.ossianhempel.com/calorieCalculator">calculator</a> as a starting point.</p>
      </section>
      {/* Add more sections as needed */}
    </div>
  );
}

export default InstructionPage;
