import React, { useState, useEffect, useCallback } from 'react';
import CheckInForm from './CheckInForm';
import CheckInList from './CheckInList';
import CheckinButton from './CheckinButton';
import styles from '../styles/Main.module.css';
import axios from 'axios';
import WeightLineChart from './WeightLineChart';
import ProgressDisplayTable from './ProgressDisplayTable';
import ConfirmationDialog from './ConfirmationDialog';
import WeeklyStreakCard from './WeeklyStreakCard';
import { useLocation } from 'react-router-dom';

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
axios.defaults.withCredentials = true;

function Main({ isAuthenticated }) {
    const [dailyCheckIns, setDailyCheckIns] = useState([]);
    const [weeklyCheckIns, setWeeklyCheckIns] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [checkInToDeleteIndex, setCheckInToDeleteIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [streak, setStreak] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const pathWithoutLeadingSlash = location.pathname.startsWith('/')
            ? location.pathname.slice(1)
            : location.pathname;
        document.title = `Fitness CheckIn - ${pathWithoutLeadingSlash.replace('/', ' - ')}`;
    }, [location]);

    const fetchStreak = useCallback(async () => {
        if (!isAuthenticated) return;
        try {
            const response = await axios.get('/user/streak');
            setStreak(response.data.streak);
        } catch (error) {
            console.error("Error fetching streak:", error);
        }
    }, [isAuthenticated]);

    const fetchCheckIns = useCallback(async () => {
        try {
            const response = await axios.get('/checkin');
            if (Array.isArray(response.data)) {
                const sortedCheckIns = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setDailyCheckIns(sortedCheckIns);
            } else {
                console.warn('Received non-array checkIns:', response.data);
                setDailyCheckIns([]);
            }
        } catch (error) {
            console.error("Error fetching check-ins:", error);
        }
    }, []);

    const fetchWeeklyCheckIns = useCallback(async () => {
        if (!isAuthenticated) return;
        try {
            const response = await axios.get('/checkin/weekly');
            console.log('Fetched weekly check-ins:', response.data); // Debugging log
            setWeeklyCheckIns(response.data.length > 0 ? response.data : []);
        } catch (error) {
            console.error("Error fetching weekly check-ins:", error);
            setWeeklyCheckIns([]);  // Ensure state is cleared on error as well
        }
    }, [isAuthenticated]);

    const fetchData = useCallback(async () => {
        await fetchCheckIns();
        await fetchWeeklyCheckIns();
        await fetchStreak();
    }, [fetchCheckIns, fetchWeeklyCheckIns, fetchStreak]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated, fetchData]);

    useEffect(() => {
        const checkViewportSize = () => {
            setIsMobile(window.matchMedia('(max-width: 700px)').matches);
        };
        checkViewportSize();
        window.addEventListener('resize', checkViewportSize);

        return () => window.removeEventListener('resize', checkViewportSize);
    }, []);

    const handleNewCheckIn = (newCheckIn) => {
        return axios.post('/checkin', newCheckIn)
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.error("Error adding new check-in:", error);
                alert("Failed to add the new check-in. Please try again.");
            });
    };

    const handleUpdateCheckIn = (index, updatedCheckIn) => {
        return axios.put(`/checkin/${updatedCheckIn.id}`, updatedCheckIn)
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.error("Error updating check-in:", error);
                alert("Failed to update the check-in. Please try again.");
            });
    };

    const handleDeleteCheckIn = index => {
        setCheckInToDeleteIndex(index);
        setConfirmDialogOpen(true);
    };

    const confirmDelete = () => {
        const checkInToDelete = dailyCheckIns[checkInToDeleteIndex];
        const newCheckIns = dailyCheckIns.filter((item, index) => index !== checkInToDeleteIndex);
        setDailyCheckIns(newCheckIns);
        setWeeklyCheckIns([]);

        axios.delete(`/checkin/${checkInToDelete.id}`)
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.error("Error deleting check-in:", error);
                alert("Something went wrong when deleting. Please try again.");
                fetchData();
            })
            .finally(() => {
                setConfirmDialogOpen(false);
                fetchStreak();
            });
    };

    const cancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    const handleEditCheckIn = index => {
        setEditingIndex(index);
        setModalOpen(true);
    };

    const [isModalOpen, setModalOpen] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
        setEditingIndex(null);
        cancelDelete();
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    // const weeklyCheckInDates = weeklyCheckIns.map(checkIn => checkIn.week_start);

    return (
        <div className={styles.main}>
            <section className={styles.main__top_section}>
                <CheckinButton setEditingIndex={setEditingIndex} setModalOpen={setModalOpen} />
                <WeeklyStreakCard streak={streak} />
            </section>

            {isModalOpen && (
                <div className={styles.modal} onClick={handleBackdropClick}>
                    <div className={styles.modalContent}>
                        <button className={styles.closeButton} onClick={() => { setEditingIndex(null); setModalOpen(false); }}>X</button>
                        <CheckInForm
                            onSubmit={(data) => {
                                const operation = editingIndex !== null
                                    ? handleUpdateCheckIn(editingIndex, data)
                                    : handleNewCheckIn(data);
                                operation.then(() => {
                                    closeModal();
                                }).catch(error => {
                                    console.error('Error during check-in operation:', error);
                                });
                            }}
                            initialData={editingIndex !== null ? dailyCheckIns[editingIndex] : null}
                            checkIns={dailyCheckIns}
                            editingIndex={editingIndex}
                        />
                    </div>
                </div>
            )}

            <ProgressDisplayTable
                checkIns={weeklyCheckIns}
            />

            <WeightLineChart
                checkIns={weeklyCheckIns}>
            </WeightLineChart>
            {!isMobile &&
                <CheckInList
                    checkIns={dailyCheckIns}
                    onDelete={handleDeleteCheckIn}
                    onEdit={handleEditCheckIn}
                />
            }

            {isConfirmDialogOpen && (
                <div className={styles.modal} onClick={handleBackdropClick}>
                    <ConfirmationDialog
                        message="Are you sure you want to delete this check-in? It can't be undone."
                        onConfirm={confirmDelete}
                        onCancel={cancelDelete}
                    />
                </div>
            )}
        </div>
    );
}

export default Main;
