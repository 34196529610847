import React from 'react';
import { useAuth } from '../Main/AuthContext'; // Adjust the path as necessary
import styles from '../styles/LogoutButton.module.css';

function LogoutButton() {
  const { logout } = useAuth();

  return (
    <button className={styles.button} onClick={() => logout()}>
      Logout
    </button>
  );
}

export default LogoutButton;

