import React, { useState, useEffect } from 'react';
import styles from '../styles/CheckInForm.module.css';
import { format } from 'date-fns';
import axios from 'axios';
import {
    validateDate, validateMacros, validateBodyWeight, validateSteps,
    validatePhase, validateComment, validateWeeklyCheckIn
} from '../utils/checkinFormValidation';

const initialState = {
    date: format(new Date(), 'yyyy-MM-dd'), // Set initial date to today
    bodyweight: '',
    steps: '',
    phase: '',
    fat: '',
    protein: '',
    carbohydrates: '',
    calories: '',
    comment: ''
};

function CheckInForm({ onSubmit, checkIns = [], initialData, editingIndex }) {
    const [formState, setFormState] = useState(initialState);
    const [errorMessage, setErrorMessage] = useState('');

    // useEffect for fetching and prefilling data from the last check-in
    useEffect(() => {
        if (editingIndex !== null && initialData) {
            // If editing, use all initial data including bodyweight and steps
            setFormState({
                ...initialState,
                ...initialData,
                date: initialData.date ? format(new Date(initialData.date), 'yyyy-MM-dd') : initialState.date,
                fat: initialData.fat || '',
                protein: initialData.protein || '',
                carbohydrates: initialData.carbohydrates || '',
                calories: initialData.calories || ''  // Assuming you want to set default calories too
            });
        } else {
            // Only fetch last check-in data if not editing
            const fetchLastCheckIn = async () => {
                try {
                    const response = await axios.get('/checkin/latest');
                    if (response.data) {
                        setFormState({
                            ...initialState,
                            phase: response.data.phase || '',
                            fat: response.data.fat || '',
                            protein: response.data.protein || '',
                            carbohydrates: response.data.carbohydrates || '',
                            calories: response.data.calories || ''  // Assuming you want to set default calories too
                        });
                    } else {
                        console.log("No previous check-ins found.");
                        setFormState({
                            ...initialState,
                            date: format(new Date(), 'yyyy-MM-dd') // ensure date is today's date
                        });
                    }
                } catch (error) {
                    console.error('Failed to fetch the last check-in:', error);
                    setFormState({
                        ...initialState,
                        date: format(new Date(), 'yyyy-MM-dd') // fallback to today's date
                    });
                }
            };
            fetchLastCheckIn();
        }
    }, [editingIndex, initialData]);
    
    // useEffect to calculate calories when macronutrients change
    useEffect(() => {
        const calculateCalories = () => {
            const fatCalories = formState.fat * 9;
            const proteinCalories = formState.protein * 4;
            const carbCalories = formState.carbohydrates * 4;
            return fatCalories + proteinCalories + carbCalories;
        };

        const calories = calculateCalories();
        setFormState(prevState => ({
            ...prevState,
            calories
        }));
    }, [formState.fat, formState.protein, formState.carbohydrates]);

    const handleChange = event => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const isFormValid = () => {
        const errorMessages = [
            validateDate(formState.date),
            validateMacros(formState.fat, formState.protein, formState.carbohydrates),
            validateBodyWeight(formState.bodyweight),
            validateSteps(formState.steps),
            validatePhase(formState.phase),
            validateComment(formState.comment),
            validateWeeklyCheckIn(formState.date, checkIns, editingIndex)
        ].filter(error => error !== "");
    
        if (errorMessages.length > 0) {
            setErrorMessage(errorMessages[0]);  // Set the first error message found
            return false;
        }
    
        return true; // Form is valid
    };

    
    const handleSubmit = event => {
        event.preventDefault();
    
        if (isFormValid(editingIndex !== null, editingIndex)) {
            const processedData = preprocessForSubmission(formState);
            // console.log("Processed check-in data for submission:", processedData);
            onSubmit(processedData);
    
            setFormState(initialState); // Reset form fields after submission
            setErrorMessage(''); // Clear any error messages
        }
    };
    

    const preprocessForSubmission = (data) => {
        // create a copy of the data to avoid modifying the original object
        const processed = { ...data };

        // Ensure weight is processed as a number
        // TODO - could be avoided with TS?
        if (processed.bodyweight) {
            processed.bodyweight = parseFloat(processed.bodyweight);
        }
        
        // iterating through the keys of the object
        Object.keys(processed).forEach(key => {
            // convert empty strings back to null for specific fields
            if (['fat', 'protein', 'carbohydrates', 'steps', 'calories'].includes(key) && data[key] === '') {
            processed[key] = null;
            }
            // convert numeric strings to numbers or null
            // Process other numeric fields
            ['steps', 'fat', 'protein', 'carbohydrates', 'calories'].forEach(field => {
                processed[field] = processed[field] ? parseFloat(processed[field]) : null;
                });
            });

        return processed;
    };

    return (
        <form className={styles.formContainer} onSubmit={handleSubmit}>
            {/* Include form fields for each attribute */}
            <input 
                type="date" 
                name="date" 
                value={formState.date} 
                onChange={handleChange} 
                className={styles.formInput}
            />
            <input 
                type="number" 
                name="bodyweight" 
                placeholder="Bodyweight" 
                value={formState.bodyweight} 
                onChange={handleChange} 
                className={styles.formInput}
            />
            <input 
                type="number" 
                name="steps" 
                placeholder="Steps" 
                value={formState.steps || ''} 
                onChange={handleChange} 
                className={styles.formInput}
            />
            <select name="phase" value={formState.phase} onChange={handleChange} className={styles.formSelect}>
                <option value="">Select phase</option>
                <option value="cutting">Cutting</option>
                <option value="massing">Massing</option>
                <option value="maintenance">Maintenance</option>
                <option value="vacation">Vacation</option>
            </select>
            <input 
                type="number" 
                name="fat" 
                placeholder="Fat" 
                value={formState.fat || ''} 
                onChange={handleChange} 
                className={styles.formInput}
            />
            <input 
                type="number" 
                name="protein" 
                placeholder="Protein" 
                value={formState.protein || ''} 
                onChange={handleChange}
                className={styles.formInput}
            />
            <input 
                type="number" 
                name="carbohydrates" 
                placeholder="Carbohydrates" 
                value={formState.carbohydrates || ''} 
                onChange={handleChange} 
                className={styles.formInput}
            />
            <p>Total Calories: {formState.calories}</p>
            <textarea name="comment" placeholder="Comment" value={formState.comment} onChange={handleChange} maxLength="200" className={styles.formTextArea} />
            <button type="submit" className={styles.button}>
                {editingIndex !== null ? "Update Check-In" : "Create Check-In"}
            </button>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        </form>
    );
}

export default CheckInForm;
