// import React from 'react';  
import styles from '../styles/LoginScreen.module.css';
import './LoginButton';
import LoginButton from './LoginButton';


function LoginScreen() {
  
  return (
    <div className={styles.container}>
        {process.env.NODE_ENV === 'development' &&
          <div>Development mode</div>
        }
        <div className={styles.container__text}>You are not logged in. Please log in to continue.</div>
        <LoginButton></LoginButton>
    </div>
  );
}

export default LoginScreen;