import React from 'react';
import styles from '../styles/LeaderboardConsentModal.module.css';

function LeaderboardConsentModal({ isOpen, onAccept, onDecline }) {
    if (!isOpen) return null;

    return (
        <div className={styles.modal} >

            <h2 className={styles.modal__header}>Join the Leaderboards</h2>
            <section className={styles.modal__body}>
                <p>In order to view other people's progress, you must share your own.</p>
                <p>Do you wish to share your progress with other users publicly?</p>
                <p>Your actual bodyweight will not be shown, but other data may be shared (e.g. weight change, steps, etc.)</p>
            </section>
            <section className={styles.modal__button_container}>
                <button className={styles.modal__button_accept} onClick={onAccept}>Accept</button>
                <button className={styles.modal__button_decline} onClick={onDecline}>Decline</button>
            </section>
        </div>
    );
}

export default LeaderboardConsentModal;
