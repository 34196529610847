import React from 'react';
import styles from '../styles/ConfirmationDialog.module.css'; // Create this CSS module

const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <div className={styles.dialog}>
      <p>{message}</p>
      <button onClick={onConfirm}>Yes</button>
      <button onClick={onCancel}>No</button>
    </div>
  );
};

export default ConfirmationDialog;
