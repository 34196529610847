import React, { useEffect, useState } from 'react';  
import { useAuth } from './Main/AuthContext';
import styles from './styles/App.module.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import GoogleCallback from './components/GoogleCallback';
import LoginScreen from './components/LoginScreen';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import '../src/Main/axiosConfig'; 
import InstructionPage from './pages/InstructionPage';
import InsightsPage from './pages/InsightsPage';
import LeaderboardsPage from './pages/LeaderboardPage';
import AdminPage from './pages/AdminPage';
import { RedirectHandler } from './Main/RedirectHandler'; 

// Reintroducing Axios defaults
axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
axios.defaults.withCredentials = true;



function App() {
  const { isLoggedIn } = useAuth(); // Use isLoggedIn from useAuth
  const [userName, setUserName] = useState(null);

    useEffect(() => {
    // Fetch the user data using Axios
    if (isLoggedIn) {
      axios.get('/getCurrentUser')
        .then(response => {
          const data = response.data; // With Axios, you access the data directly via response.data
          if (data.isAuthenticated) {
            setUserName(data.display_name);
          }
        })
        .catch(error => {
          console.error('An error occurred:', error); // Handle any errors here
        });
    } 
  }, [isLoggedIn]);

  return (
    <Router>
      <div className={styles.app}>
        <RedirectHandler /> {/* This will handle the redirects */}
        <Header userName={userName} />
        <Routes>
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/login" element={!isLoggedIn ? <LoginScreen /> : <Navigate to="/main" />} />
          <Route path="/main" element={isLoggedIn ? <Main isAuthenticated={isLoggedIn} /> : <Navigate to="/login" />} />
          <Route path="/" element={isLoggedIn ? <Navigate to="/main" /> : <Navigate to="/login" />} />
          <Route path="/success" element={isLoggedIn ? <Navigate to="/main" /> : <Navigate to="/login" />} />
          <Route path="/instructions" element={<InstructionPage />} />
          <Route path="/insights" element={<InsightsPage />} />
          <Route path="/leaderboards" element={< LeaderboardsPage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

