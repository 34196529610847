import React from 'react';
import CheckIn from './CheckIn';
import styles from '../styles/CheckInList.module.css';

function CheckInList({ checkIns, onDelete, onEdit }) {

  if (!checkIns || !Array.isArray(checkIns)) {
    return <div className={styles.checkInListClass}>No check-ins yet.</div>;
  }

  return (
    <div>
      <header className={styles.header}>
        <div>Date</div>
        <div>Weight</div>
        <div>Steps</div>
        <div>Phase</div>
        <div>Fat</div>
        <div>Protein</div>
        <div>Carbohydrates</div>
        <div>Calories</div>
        <div>Comment</div>
      </header>
      <div className={styles.checkInListClass}>
        {checkIns.map((checkIn, index) => (
            <CheckIn
                key={`checkin-${checkIn.id}`}
                {...checkIn}
                onDelete={() => onDelete(index)}
                onEdit={() => onEdit(index)}
            />
        ))}
      </div>
    </div>
  );
}

export default CheckInList;
