import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); 

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    try {
      const response = await axios.get('/isAuthenticated');
      setIsLoggedIn(response.data.isAuthenticated);
      // Step 3: Update isAdmin based on the role from the response
      // Assume default role is 'user' if not specified
      const role = response.data.role || 'user';
      setIsAdmin(role === 'admin');
    } catch (error) {
      console.error('Error checking authentication status:', error);
      setIsLoggedIn(false);
      setIsAdmin(false); // Ensure isAdmin is also set to false if there's an error
    }
  };
  

  const logout = async () => {
    try {
      await axios.post('/logout');
      setIsLoggedIn(false);
      // No direct useNavigate call here
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  // Provide the `logout` method and the `checkAuthentication` method to allow manual re-checking if needed
  return (
    <AuthContext.Provider value={{ isLoggedIn, isAdmin, logout, checkAuthentication }}>
      {children}
    </AuthContext.Provider>
  );
};
