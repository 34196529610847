// components/UpdateStreakButton.js
import React from 'react';
import axios from 'axios';
import styles from '../styles/BackupDataButton.module.css'; // You can use the same styles or create new ones

const UpdateStreakButton = () => {
  // Handler for updating user streaks
  const handleUpdateStreaks = async () => {
    try {
      const response = await axios.post('/admin/update-streaks'); // Adjusted endpoint
      if (response.status === 200) {
        alert('All users streaks updated successfully.');
      } else {
        alert('Failed to update users streaks.');
      }
    } catch (error) {
      console.error('Failed to update users streaks:', error);
      alert('An error occurred while updating users streaks.');
    }
  };

  return (
    <div>
      <button className={styles.downloadButton} onClick={handleUpdateStreaks}>
        Update User Streaks
      </button>
    </div>
  );
};

export default UpdateStreakButton;
