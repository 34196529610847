import React from 'react';
import styles from '../styles/AdminPage.module.css'; 
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import BackupDataButton from '../components/BackupDataButton';
import UpdateStreakButton from '../components/UpdateStreaksButton'; // Import the new component

function AdminPage() {
    const location = useLocation();
    useEffect(() => {
        // Remove the leading slash from the pathname if it exists
        const pathWithoutLeadingSlash = location.pathname.startsWith('/')
            ? location.pathname.slice(1)
            : location.pathname;

        // Now set the document title using the modified path
        document.title = `Fitness CheckIn - ${pathWithoutLeadingSlash.replace('/', ' - ')}`;
        // Replace remaining slashes with dashes or any other separator you prefer
    }, [location]);
return (
    <div className={styles.container}>
        <BackupDataButton></BackupDataButton>
        <UpdateStreakButton /> {/* Add the new component */}
    </div>
);
}

export default AdminPage;
