import { startOfWeek, addDays } from 'date-fns';

/**
 * Validates the date field.
 */
export const validateDate = (date) => {
    if (!date) return "Date is required";
    if (new Date(date).getFullYear() < 2000) return "Date should be in the 2000s";
    if (new Date(date) > addDays(new Date(), 1)) {
        return "Date cannot be more than one day in the future";
    }
    return "";
};

/**
 * Validates macro nutrients inputs.
 */
export const validateMacros = (fat, protein, carbohydrates) => {
    const macros = [fat, protein, carbohydrates];
    const filledMacros = macros.filter(macro => macro !== '');
    if (filledMacros.length > 0 && filledMacros.length < 3) {
        return "Please fill in all macros (fat, protein, carbohydrates) or none";
    }
    return "";
};

/**
 * Validates body weight.
 */
export const validateBodyWeight = (bodyweight) => {
    if (!bodyweight || bodyweight < 50 || bodyweight > 150) {
        return "Weight should be between 50 and 150 kg and cannot be empty";
    }
    return "";
};

/**
 * Validates steps count.
 */
export const validateSteps = (steps) => {
    steps = parseInt(steps, 10) || 0;
    if (steps < 0 || steps >= 50000) {
        return "Steps should be between 0 and 50000";
    }
    return "";
};

/**
 * Validates the phase field.
 */
export const validatePhase = (phase) => {
    if (!phase) return "Phase is required";
    return "";
};

/**
 * Validates the comment length.
 */
export const validateComment = (comment) => {
    if (comment && comment.length > 200) {
        return "Comment should be limited to 200 characters";
    }
    return "";
};

/**
 * Validates if there's already a check-in for the given week.
 */
export const validateWeeklyCheckIn = (date, checkIns, editingIndex) => {
    const startOfWeekDate = startOfWeek(new Date(date), { weekStartsOn: 1 }).toISOString();
    const hasCheckIn = checkIns.some(checkIn => {
        if (editingIndex !== null && checkIns[editingIndex] && checkIn.id === checkIns[editingIndex].id) {
            return false; // Skip this check-in because it's being edited
        }
        const checkInStartOfWeekDate = startOfWeek(new Date(checkIn.date), { weekStartsOn: 1 }).toISOString();
        return checkInStartOfWeekDate === startOfWeekDate;
    });

    if (hasCheckIn) {
        return "You've already checked in this week";
    }
    return "";
};

