import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/WeeklyStreakCard.module.css';

const WeeklyStreakCard = ({ streak }) => {
    return (
        <div className={styles.card}>
            <h2>Weekly Check-In Streak</h2>
            <div className={styles.streakCount}>
                {streak}
            </div>
            <p>{streak === 1 ? 'week' : 'weeks'} in a row!</p>
        </div>
    );
};

WeeklyStreakCard.propTypes = {
    streak: PropTypes.number.isRequired,  // Ensure that streak is a required prop
};

export default WeeklyStreakCard;
