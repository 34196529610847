import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
axios.defaults.withCredentials = true;

// Interceptor for handling expired sessions
axios.interceptors.response.use(response => response, error => {
  if (error.response && error.response.status === 401) {
    // Assuming you have a way to clear the authentication state
    console.log('Request failed with 401')
    window.location = '/login'; // Force a redirect to the login page
  }
  return Promise.reject(error);
});
