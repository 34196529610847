

export const getCurrentPhase = (checkIns) => {
    for (let i = checkIns.length - 1; i >= 0; i--) {
        if (checkIns[i].phase !== 'vacation' && checkIns[i].phase !== 'maintenance') {
            return checkIns[i].phase;
        }
    }
    return null;
}

export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function calculateWeeksBetweenDates(startDate, endDate) {
    // convert both dates to UTC to avoid time zone issues
    const startUTC = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const endUTC = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    // calculate the difference in milliseconds
    const diffInMilliseconds = endUTC - startUTC;

    // convert milliseconds to weeks
    const millisecondsInAWeek = 1000 * 60 * 60 * 24 * 7;
    const diffInWeeks = diffInMilliseconds / millisecondsInAWeek;

    return diffInWeeks;
}

// export const getWeekNumberAndYear = (dateString: string) => {
//     const date = new Date(dateString);
//     date.setHours(0, 0, 0, 0);
//     date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
//     const jan4 = new Date(date.getFullYear(), 0, 4);
//     const daysBetween = (date.getTime() - jan4.getTime()) / (24 * 60 * 60 * 1000);
//     const weekNumber = Math.ceil((daysBetween + 1) / 7);
//     const year = date.getFullYear();
//     return `Week ${weekNumber}, ${year}`;
//   };