import React, { useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const GoogleCallback = () => {
  const navigate = useNavigate(); // React Router's hook for navigation

  useEffect(() => {
    // Call your API to confirm the user is authenticated
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/confirmGoogleLogin`, { withCredentials: true })
    .then(response => {
        if(response.data.isAuthenticated) {
          // Update your `isAuthenticated` state if you have one
          // Then navigate to the main page
          // console.log(response.data)
          navigate('/main');
        } else {
          // Handle login failure, perhaps navigate to the login page with an error message
          console.log('User is not authenticated')
          navigate('/login');
        }
      })
      .catch(error => {
        console.error('Failed to confirm Google login:', error);
        // Handle error, perhaps navigate to the login page with an error message
        navigate('/login');
      });
  }, [navigate]); // `navigate` is a dependency of this `useEffect`

  return <div>Processing login...</div>;
};

export default GoogleCallback;
