import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import LeaderboardConsentModal from '../components/LeaderboardConsentModal';
import LeaderboardList from '../components/LeaderboardList';
import styles from '../styles/LeaderboardPage.module.css';

const LeaderboardsPage = ({ userId }) => {
    const [isConsentGiven, setIsConsentGiven] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userDeclined, setUserDeclined] = useState(false);

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetch(`${baseUrl}/user/leaderboard`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(async (response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log("Consent status data:", data);
            if (data.hasOwnProperty('opt_in')) {
                setIsConsentGiven(data.opt_in);
            } else {
                setIsConsentGiven(false);
            }
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching consent status:', error);
            setLoading(false);
            setIsConsentGiven(false);
        });
    }, [userId, baseUrl]);

    const handleAccept = () => {
        updateConsent(true);
    };

    const handleDecline = () => {
        updateConsent(false);
        setUserDeclined(true);
    };

    const updateConsent = (consent) => {
        fetch(`${baseUrl}/user/leaderboard`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ opt_in: consent }),
            credentials: 'include'
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setIsConsentGiven(consent);
            if (!consent) {
                setUserDeclined(true);
            }
        })
        .catch(error => {
            console.error('Error updating consent status:', error);
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    } else if (userDeclined) {
        return <Navigate to="/" replace />;
    }

    return (
        <>
            {isConsentGiven ? (
                <div className={styles.container}>
                    <LeaderboardList />
                </div>
            ) : (
                <div>
                    <LeaderboardConsentModal
                        isOpen={!isConsentGiven && !userDeclined}
                        onAccept={handleAccept}
                        onDecline={handleDecline}
                    />
                </div>
            )}
        </>
    );
};

export default LeaderboardsPage;
