import React, { 
    useEffect, 
    useRef 
} from 'react';

import { 
    Chart, 
    LineController, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement, 
    TimeScale,
    registerables
} from 'chart.js';
import 'chartjs-adapter-date-fns';  // Import the adapter
import annotationPlugin from 'chartjs-plugin-annotation';
import styles from '../styles/WeightLineChart.module.css';
import { format, max } from 'date-fns';
Chart.register(...registerables, annotationPlugin);



// Register the controllers, elements, and scales
Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, TimeScale);

const WeightLineChart = ({ checkIns }) => {
    const chartRef = useRef(null);
    const myChartRef = useRef(null);

    useEffect(() => {
        if (myChartRef.current) {
            myChartRef.current.destroy();
        }
    
        const sortedCheckIns = [...checkIns].sort((a, b) => new Date(a.week_start) - new Date(b.week_start));
        // console.log("Sorted CheckIns in WeightLineChart:", sortedCheckIns);

        const style = getComputedStyle(document.documentElement);
        const signatureBlue = style.getPropertyValue('--signature-blue').trim();
        const backgroundDarkGrey = style.getPropertyValue('--background-dark-grey').trim();
        const backgroundLightGrey = style.getPropertyValue('--background-light-grey').trim();
    
        // Convert weights to numbers if they're not already
        const data = {
            labels: sortedCheckIns.map(c => c.week_start),
            datasets: [
                {
                    type: 'line',
                    label: 'Bodyweight',
                    data: sortedCheckIns.map(c => ({
                        x: c.week_start,
                        y: c.avg_weight, 
                    })),
                    borderColor: signatureBlue,
                    pointBackgroundColor: signatureBlue,
                    backgroundColor: signatureBlue, 
                    borderWidth: 2,
                    pointRadius: 4,
                    hitRadius: 15, // how far from the point you can hover
                    hoverRadius: 10,
                    tension: 0.4, // Adjust for smoother curves
                    fill: false,
                    yAxisID: 'y-weight',
                },
                {
                    type: 'bar',
                    label: 'Steps',
                    data: sortedCheckIns.map(c => ({
                        x: c.week_start,
                        y: c.avg_steps, 
                    })),
                    borderColor: backgroundDarkGrey,
                    borderWidth: 0.2,
                    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: backgroundLightGrey,
                    pointRadius: 0,
                    hitRadius: 0,
                    fill: false,
                    yAxisID: 'y-steps',
                    // disable tooltip and hover effect for this axis
                    hoverRadius: 0,
                    hoverBorderWidth: 0,
                    showLine: false,
                },
            ],
        };

        myChartRef.current = new Chart(chartRef.current, {
            // type: 'line',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        min: sortedCheckIns[0]?.week_start,
                        type: 'time',
                        time: {
                            unit: 'week',
                            minUnit: 'week',
                            displayFormats: {
                                week: 'MMM d yyyy',
                            },
                        ticks: {
                            source: 'data',
                            autoSkip: true,
                            autoSkipPadding: 20,
                            maxRotation: 0,
                            },
                        },
                        title: {
                            display: true,
                            text: 'Date',
                        },
                    },
                    'y-weight': {
                        title: {
                            display: true,
                            text: 'Bodyweight',
                        },
                    },
                    'y-steps': {
                        title: {
                            display: true,
                            text: 'Steps',
                        },
                        position: 'right',
                        grid: {
                            drawOnChartArea: false, // Only draws grid for the first axis
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        interaction: {
                            mode: 'point',
                            axis: 'xy',
                            intersect: false,
                        },
                        callbacks: {
                            title: function(context) {
                                const rawDate = context[0]?.raw?.x;
                                if (rawDate) {
                                    // Format the date. Adjust the format string as needed.
                                    const formattedDate = format(new Date(rawDate), 'PPP'); // Example: 'Jan 1, 2020'
                                    return formattedDate;
                                }
                                // Fallback to the default label if for some reason the raw date isn't available
                                return context[0]?.label;
                            },
                            label: function(context) {
                                const label = context.dataset.label || '';
                                const value = context.parsed.y;
                                // console.log(value)
                                // console.log(context.raw.x)
                                // console.log(context)
                                return `${label}: ${value}`;
                            },
                            afterLabel: function(context) {
                                // Only add additional info for the "Weight" dataset
                                if (context.dataset.label === 'Bodyweight') {
                                    const dataIndex = context.dataIndex;
                                    const checkIn = sortedCheckIns[dataIndex];
            
                                    let additionalInfo = `Calories: ${Math.round(checkIn.avg_calories) || 'N/A'}`;
                                    if (checkIn.phase) {
                                        additionalInfo += `\nDiet Phase: ${checkIn.phase}`;
                                    }
            
                                    return additionalInfo;
                                }
                                return ''; // For non-weight datasets, don't add extra info
                            },
                        },
                        filter: function(item, context) {
                            const datasetLabel = item.dataset.label;
            
                            // Return true to show the tooltip for this item only if the dataset label is not 'Steps'
                            return datasetLabel !== 'Steps';
                        }
                    }
                },
            },
        });
    }, [checkIns]);

    return (
        <div className={styles.container}> 
        <canvas className={styles.canvas} ref={chartRef} />
        </div>
    );
};

export default WeightLineChart;
