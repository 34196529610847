import React, { useEffect, useState } from 'react';
import styles from '../styles/LeaderboardList.module.css';

const LeaderboardList = () => {
    const [users, setUsers] = useState([]);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                const response = await fetch(`${baseUrl}/leaderboard`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (Array.isArray(data)) {
                    setUsers(data);
                } else {
                    console.error('Expected an array but received:', data);
                    setUsers([]);
                }
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
                setUsers([]);
            }
        };

        fetchLeaderboard();
    }, [baseUrl]);

    return (
        <div className={styles.leaderboard}>
            <h2 className={styles.leaderboard__header}>Leaderboard</h2>
            {users.length > 0 ? (
                users.map((user, index) => (
                    <div key={index} className={`${styles.user} ${index < 3 ? styles['top-three'] : ''}`}>
                        <div className={styles.rank}>{index + 1}</div>
                        <div className={styles.name}>{user.display_name}</div>
                        <div className={styles.streak}>{user.streak} weeks</div>
                    </div>
                ))
            ) : (
                <div className={styles['no-users']}>No users available</div>
            )}
        </div>
    );
};

export default LeaderboardList;
